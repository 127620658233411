<script lang="ts" setup>
const {
  public: { appLink },
} = useRuntimeConfig()

const { t } = useI18n()
</script>

<template>
  <NuxtLink
    target="_blank"
    :to="appLink"
    aria-label="Launch Web App"
    class="border-2 border-solid border-white rounded-full flex flex-row items-center gap-2.5 px-3.5 py-2.5 h-14 px-10 justify-center uppercase whitespace-pre"
  >
    <img alt="App" src="/assets/images/icons/website.svg" />
    {{ t('web-app') }}
  </NuxtLink>
</template>
